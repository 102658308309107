<!-- 설비 계통 관리 메인 -->
<template>
  <div class="ld-container" style="height: 90vh; width: 100%">
    <div class="row" style="height: 100%">
      <div class="list-area col-3">
        <div class="row">
          <header-box-component
            :title="`${$t('운영 기준정보 관리')} > ${$t(`${title}`)}`"
          />
        </div>
        <div class="tree-header">
          <div class="tree-title col-8">
            <div>
              <span class="title">{{
                $t(`${routeType == "energy" ? "에너지 계통" : "설비 계통"}`)
              }}</span>
            </div>
            <div class="flex-grow-1">
              <select
                class="form-control"
                v-model="sysType"
                @change="getTreeList"
              >
                <option :value="null">{{ $t("선택") }}</option>
                <option
                  v-for="list in systemTypeList"
                  :key="list.sysType"
                  :value="list.sysType"
                >
                  {{ list.sysTypeName }}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="btn-group mr-2" v-show="controls && controls.length > 0">
                        <button v-for="(control, index) in controls" :key="index" type="button"
                            :class="'btn btn-sm ' + control.class" @click="handleButtonClick(control.event)">
                            {{ control.label }}
                        </button>
                    </div> -->
        </div>
        <div
          class="tree-area"
          v-if="Array.isArray(treeData) && treeData.length > 0 && sysType"
        >
          <liquor-tree
            ref="tree"
            :data="treeData"
            :options="treeOptions"
            @node:selected="onNodeSelected"
            @node:dragging:start="logDragStart"
            @node:dragging:finish="logDragFinish"
            @node:expanded="onNodeExpand"
          >
            <div
              slot-scope="{ node }"
              class="node-container d-flex align-items-center"
              @contextmenu.prevent="showContextMenu($event, node)"
              @click="hideContextMenu"
            >
              <!-- @contextmenu.prevent="showContextMenu($event, node)" @click="hideContextMenu" -->
              <span
                class="treenode-icon"
                :style="{ color: node.data.sumEnabled == 'Y' ? '#EF5350' : '' }"
              >
                <!-- 계통노드 아이콘 -->
                <i
                  v-if="
                    node.data.nodeType == 'Root' ||
                    node.data.nodeType == 'System'
                  "
                  class="fas fa-sitemap"
                  aria-hidden="true"
                ></i>
                <!-- 그룹노드 아이콘 -->
                <i
                  v-else-if="node.data.nodeType == 'Group'"
                  class="fas fa-folder-open"
                  aria-hidden="true"
                ></i>
                <!-- 설비노드 아이콘 : 설비유형의 아이콘 -->
                <i
                  v-else
                  :class="
                    node.data.equipType
                      ? equipTypeList.find(
                          (item) => item.equipType == node.data.equipType
                        ).icon
                      : ''
                  "
                  aria-hidden="true"
                ></i>
              </span>

              <div v-if="editingNode && editingNode.id === node.id">
                <input
                  class="form-control"
                  type="text"
                  :ref="'input-' + node.id"
                  v-model="node.text"
                  @blur="cancelEditNode"
                  @keydown.enter="finishEditNode(node)"
                  @keydown.esc="cancelEditNode"
                />
              </div>
              <div v-else>
                {{ node.text }}
              </div>
            </div>
          </liquor-tree>
        </div>
        <div
          v-else-if="!treeData && sysType"
          class="tree-area-placeholder"
        ></div>
        <div v-else class="tree-area-placeholder">
          <span class="detail-notification-icon">
            <i class="fas fa-external-link-alt"></i>
          </span>
          <span class="detail-notification-text">설비 계통을 선택하세요.</span>
        </div>
        <!-- <div class="dropdown-menu dropdown-menu-sm"
                    :style="{ display: contextMenuVisible ? 'block' : 'none', top: contextMenuTop + 'px', left: contextMenuLeft + 'px' }">
                    <span class="dropdown-item" style="cursor: pointer">사용 변경</span>
                    <span class="dropdown-item" style="cursor: pointer">노드 삭제</span>
                    <span class="dropdown-item" style="cursor: pointer">하위 그룹 추가</span>
                    <span class="dropdown-item" style="cursor: pointer">하위 설비 추가</span>
                </div> -->
      </div>

      <!-- Detail Panel -->
      <div class="detail-area col-9">
        <tree-detail
          ref="tree-detail"
          :isSystemEmpty="isSystemEmpty"
          :sysType="sysType"
          @delete="deleteSysNode"
          @save="saveNode"
          @init="initSysNodeTree"
        />
      </div>
    </div>
    <div
      v-if="nodeInfo"
      class="dropdown-menu dropdown-menu-sm"
      :style="{
        display: contextMenuVisible ? 'block' : 'none',
        top: contextMenuTop + 'px',
        left: contextMenuLeft + 'px',
      }"
    >
      <span
        v-if="nodeInfo.nodeType == 'Root'"
        class="dropdown-item"
        style="cursor: pointer"
        @click="openAddModal('System')"
      >
        계통 노드 추가
      </span>
      <span
        v-if="nodeInfo.nodeType !== 'Equip'"
        class="dropdown-item"
        style="cursor: pointer"
        @click="openAddModal('Group')"
      >
        그룹 노드 추가
      </span>
      <span
        v-if="nodeInfo.nodeType == 'Group' && nodeInfo.nodeType !== 'Equip'"
        v-b-toggle.equipAddSideBar
        class="dropdown-item"
        style="cursor: pointer"
        @click="addEquip"
      >
        설비 노드 추가
      </span>
      <span
        v-if="nodeInfo.nodeType !== 'Root'"
        class="dropdown-item"
        style="cursor: pointer"
        @click="deleteSysNode(nodeInfo)"
      >
        노드 삭제
      </span>
      <span
        v-if="
          $store.state.systemTypeList.find(
            (type) => type.sysType == nodeInfo.sysType
          ).sumAvail == 'Y'
        "
        class="dropdown-item"
        style="cursor: pointer"
        @click="setSumEnabled(nodeInfo)"
      >
        계통 집계 활성화
      </span>
    </div>

    <!-- PopUp & Modal -->
    <!-- <AddNodeModal v-if="currentNode" ref="AddNodeModal" :node="currentNode" @save="AddSysNode" /> -->
    <AddNodeModal
      v-if="nodeInfo"
      ref="AddNodeModal"
      :node="nodeInfo"
      :routeType="routeType"
      @save="AddSysNode"
    />
    <b-sidebar
      ref="equipSideBar"
      id="equipAddSideBar"
      bg-variant="light"
      backdrop-variant="secondary"
      width="33vw"
      @hidden="closeSidebar"
      no-header
      shadow
      right
      backdrop
    >
      <div v-if="sideBar" style="padding: 2rem; height: 100%">
        <EquipSearch
          ref="equipSearch"
          :nodeInfo="nodeInfo"
          :routeType="routeType"
        />
      </div>
      <template #footer>
        <div class="d-flex align-items-center" style="padding: 1rem; flex: 1">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            style="flex: 1; margin-right: 0.5rem"
            @click="setNewEquip"
          >
            추가
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            style="flex: 1; margin-left: 0.5rem"
            @click="closeSidebar"
          >
            취소
          </button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import HeaderBoxComponent from "@views/component/headerBox/analysis/Header.vue";
import LiquorTree from "liquor-tree";
import TreeDetail from "./EquipSysMgmtDetail.vue";
import AddNodeModal from "./modal/AddNodeModal.vue";
import EquipSearch from "@src/views/component/v2.1/PointSearch/EquipSearch.vue";
import backEndApi from "@src/api/backEndApi";
import { mapState } from "vuex";
// import moment from 'moment'

export default {
  components: {
    HeaderBoxComponent,
    TreeDetail,
    LiquorTree,
    AddNodeModal,
    EquipSearch,
  },
  props: [],
  data() {
    return {
      controls: [
        { event: "delete", label: "삭제", class: "btn-danger" },
        { event: "regist", label: "추가", class: "btn-primary" },
      ],
      isShow: false,
      treeData: null,
      treeOptions: {
        parentSelect: true,
        dnd: true,
        deletion: (node) => !node.hasChildren(),
      },

      selectedNode: null,
      editingNode: null,
      originalNodeText: "",

      sysType: null,

      rootList: null,

      currentNode: null,
      equipCheck: true,
      equipData: null,

      contextMenuTop: null,
      contextMenuLeft: null,
      contextMenuVisible: false,
      nodeInfo: null,
      sideBar: false,

      isSystemEmpty: false,

      mode: null,

      moveNodeIdx: null,
      fromNodeIdx: null,
      toNodeIdx: null,
      systemTypeList: [],
      title: "",
      routeType: null,
    };
  },
  computed: {
    // ...mapState({
    //     systemTypeList: state => state.systemTypeList
    // }),
    ...mapState({
      equipTypeList: (state) => state.equipTypeList,
    }),
    isNewMode() {
      return this.mode == "new";
    },
    // findSystemType() {

    // }
  },
  watch: {
    treeData(newVal) {
      this.isShow = false;
      if (newVal) {
        // this.isSystemEmpty = false;
        this.$nextTick(() => {
          this.isShow = true;
        });
      } else {
        // this.isSystemEmpty = true;
      }
    },
    "$route.params.type": {
      deep: true,
      async handler() {
        console.log("this.$route.params.type watch");
        await this.getSysTypeList();
      },
    },
  },
  async created() {
    await this.getRootList();
    this.getSysTypeList();
  },
  async mounted() {
    document.addEventListener("click", this.handleDocumentClick);

    window.addEventListener("keydown", this.handleKeyDown);
  },
  destroyed() {
    document.removeEventListener("click", this.handleDocumentClick);

    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    getSysTypeList() {
      this.sysType = null;
      this.currentNode = null;
      this.routeType = this.$route.params.type;
      if (this.routeType == "energy") {
        this.systemTypeList = this.$store.state.systemTypeList.filter(
          (item) => item.sumAvail == "Y"
        );
        this.title = "부하 계통 구성 관리";
      } else {
        this.systemTypeList = this.$store.state.systemTypeList.filter(
          (item) => item.sumAvail == "N"
        );
        this.title = "설비 계통 구성 관리";
      }
      this.$refs["tree-detail"].clearData();
    },
    showContextMenu(event, node) {
      this.contextMenuTop = event.pageY + 10;
      this.contextMenuLeft = event.pageX + 10;
      this.contextMenuVisible = true;
      this.nodeInfo = node.data;
    },
    hideContextMenu() {
      this.contextMenuVisible = false;
    },
    async addEquip() {
      this.showSideBar();
      this.hideContextMenu();
    },
    showSideBar() {
      this.sideBar = true;
    },
    closeSidebar() {
      this.$refs.equipSideBar.hide();
      this.sideBar = false;
    },
    async setNewEquip() {
      let equips = await this.$refs.equipSearch.selectedEquip();
      if (equips.length > 0) {
        await equips.forEach(async (equip) => {
          let node = {
            mainNodeIdx: this.nodeInfo.sysNodeIdx,
            sysNodeName: equip.equipName,
            nodeType: "Equip",
            sysType: this.nodeInfo.sysType,
            equipType: this.nodeInfo.equipType,
            equipIdx: equip.equipIdx ? equip.equipIdx : null,
          };
          try {
            let result = await backEndApi.sysNode.addSysNode(node);
            if (result.status !== 200) {
              let detail = "";
              if (result.data.errcode)
                await this.alertWarning(result.data.message);
              else {
                result.data.detail.forEach((item) => (detail += item));
                await this.alertWarning(result.data.message, "", detail);
              }
              return;
            } else {
              await this.getTreeList();
            }
          } catch (e) {
            console.error(e);
          }
        });
        await this.alertNoti("설비 노드를 추가하였습니다.");
        this.closeSidebar();
      } else {
        try {
          let nodeDetail = await backEndApi.sysNode.getSysNode(
            this.nodeInfo.sysNodeIdx
          );
          if (nodeDetail.status == 200) {
            let node = {
              mainNodeIdx: this.nodeInfo.sysNodeIdx,
              sysNodeName: `DEFAULT NODE NAME (${
                nodeDetail.data.subNodes.length + 1
              })`,
              nodeType: "Equip",
              sysType: this.nodeInfo.sysType,
              equipType: this.nodeInfo.equipType,
              equipIdx: null,
            };
            try {
              let result = await backEndApi.sysNode.addSysNode(node);
              if (result.status !== 200) {
                let detail = "";
                if (result.data.errcode)
                  await this.alertWarning(result.data.message);
                else {
                  result.data.detail.forEach((item) => (detail += item));
                  await this.alertWarning(result.data.message, "", detail);
                }
                return;
              } else {
                await this.alertNoti("설비 노드를 추가하였습니다.");
                await this.getTreeList();
                this.closeSidebar();
              }
            } catch (e) {
              console.error(e);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
      // await this.getTreeList();
    },
    handleDocumentClick() {
      if (this.contextMenuVisible) {
        this.hideContextMenu();
      }
    },
    async equipControl(data) {
      let ctrlCmd = {
        sysNodeIdx: this.currentNode.sysNodeIdx,
        equipType: data[0].equipType,
        ctrlMode: "Basic",
        expandSearch: "Y",
        propList: [
          {
            propCode: data[0].ioProps[0].propCode,
            ptVal: data[0].ioProps[0].ptVal,
          },
        ],
      };
      try {
        let result = await backEndApi.sysNode.sendGroupCtrlCmd(ctrlCmd);
        if (result.data[0].result === "success") {
          await this.alertNoti("제어에 성공하였습니다.");
        } else this.alertNoti("제어에 실패하였습니다.");
      } catch (e) {
        console.error(e);
      }
    },

    async onNodeSelected(node) {
      try {
        this.selectedNode = node;
        let result = await backEndApi.sysNode.getSysNode(node.data.sysNodeIdx);
        if (result.status == 200) {
          this.currentNode = result.data;
          await this.$refs["tree-detail"].setData(this.currentNode);
          // await this.$refs['tree-detail'].setComponentData(this.currentNode);
        }
      } catch (e) {
        console.error(e);
      }
    },
    startEditNode(node) {
      this.editingNode = node;
      this.originalNodeText = node.text;
      this.$nextTick(() => {
        const input = this.$refs[`input-${node.id}`];
        if (input) {
          input.focus();
          input.select();
        }
      });
    },
    async finishEditNode(node) {
      let item = {
        sysNodeIdx: node.data.sysNodeIdx,
        sysNodeName: node.data.text,
      };
      try {
        let result = await backEndApi.sysNode.saveSysNode(item);
        if (result.status == 200) {
          this.currentNode = result.data.data;
          await this.$refs["tree-detail"].setData(this.currentNode);
        }
      } catch (e) {
        this.cancelEditNode();
        await this.alertNoti("노드명을 바꿀 수 없습니다.");
        console.error(e);
      }
      this.editingNode = null;
    },
    cancelEditNode() {
      if (this.editingNode) {
        this.editingNode.text = this.originalNodeText;
        this.editingNode = null;
      }
    },
    handleKeyDown(event) {
      if (event.key === "F2" && this.selectedNode) {
        this.startEditNode(this.selectedNode);
      }
    },

    async getRootList() {
      try {
        let result = await backEndApi.sysNode.getRootNodeList("Y");
        if (result.status == 200) this.rootList = result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getTreeList() {
      this.treeData = null;
      this.selectedNode = null;
      this.currentNode = null;
      this.$refs["tree-detail"].clearData();

      if (this.sysType) {
        let find = this.rootList.find((root) => root.sysType == this.sysType);

        if (find) {
          this.isSystemEmpty = false;
          try {
            let result = await backEndApi.sysNode.getSysNodeTree(
              find.sysNodeIdx
            );
            if (result.status == 200) {
              let newNode = await this.getCustomTreeData(result.data);
              this.treeData = newNode;
            }
          } catch (e) {
            console.error(e);
          }
        } else {
          this.isSystemEmpty = true;
        }
      } else {
        this.isSystemEmpty = false;
      }
    },
    getCustomTreeData(data) {
      return data.map((node) => {
        const { text, children, ...rest } = node;
        return {
          text,
          children: children ? this.getCustomTreeData(children) : [],
          state: { expanded: true },
          data: rest,
        };
      });
    },
    async handleButtonClick(event, options) {
      const that = this;

      switch (event) {
        case "regist":
          await that.openAddModal();
          break;

        case "delete":
          await that.deleteSysNode();
          break;

        default:
          console.warn(
            "Unhandled Component Event. event:",
            event,
            "options:",
            options
          );
          break;
      }
    },
    async openAddModal(nodeType) {
      // this.$nextTick(async () => {
      //     if (!this.currentNode) this.alertNoti("노드를 선택해주세요.")
      //     else {
      //         await this.$refs.AddNodeModal.initData();
      //         await this.$refs.AddNodeModal.showAddNodeModal();
      //     }
      // })
      await this.$refs.AddNodeModal.initData(nodeType);
      await this.$refs.AddNodeModal.showAddNodeModal();
    },
    async AddSysNode(nodeInfo, sysNodeIdx, sumAvail) {
      let newNode = {
        nodeType: nodeInfo.nodeType,
        sysNodeName: nodeInfo.sysNodeName,
        sysType: nodeInfo.sysType,
        equipType: nodeInfo.equipType,
        mainNodeIdx: sysNodeIdx,
      };
      if (sumAvail == "Y") {
        newNode = {
          ...newNode,
          sumEnabled: nodeInfo.sumEnabled,
          sumUnitType: nodeInfo.sumUnitType,
          sumUnit: nodeInfo.sumUnit,
          sumCondType: nodeInfo.sumCondType,
          sumCond: nodeInfo.sumCond,
          sumMethod: nodeInfo.sumMethod,
        };
      }
      try {
        let result = await backEndApi.sysNode.addSysNode(newNode);
        if (result.status == 200) {
          await this.alertNoti("저장하였습니다.");
          await this.$refs.AddNodeModal.hideAddNodeModal();
          await this.getTreeList();
          // await this.$refs['tree-detail'].clearData();
        } else {
          console.log("save error");
          let detail = "";
          if (result.data.errcode) await this.alertWarning(result.data.message);
          else {
            result.data.detail.forEach((item) => (detail += item));
            await this.alertWarning(result.data.message, "", detail);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteSysNode(nodeInfo) {
      let sysNodeIdx = nodeInfo
        ? nodeInfo.sysNodeIdx
        : this.currentNode.sysNodeIdx;
      try {
        let confirm = await this.alertConfirmWarning("삭제하시겠습니까?");
        if (!confirm.value) return;
        else {
          let result = await backEndApi.sysNode.deleteSysNode(sysNodeIdx);
          if (result.data) {
            await this.alertNoti("삭제하였습니다.");
            await this.getTreeList();
            await this.$refs["tree-detail"].clearData();
          } else this.alertNoti("실패하였습니다.");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async setSumEnabled(nodeInfo) {
      let sumEnabled = {
        sysNodeIdx: nodeInfo.sysNodeIdx,
        sumEnabled: "Y",
      };
      try {
        let result = await backEndApi.sysNode.setSumEnabled(sumEnabled);
        if (result.data.result == "Success") {
          await this.alertNoti("계통 집계가 활성화 되었습니다.");
        } else {
          const confirm = await this.alertConfirmWarning(
            `${result.data.data[0].sysNodeName}에 자동 집계가 활성화 되어 있습니다. 현재 노드로 집계를 활성화 하시겠습니까?`
          );
          if (confirm.value) {
            sumEnabled.forceUpdate = "Y";
            let forceResult = await backEndApi.sysNode.setSumEnabled(
              sumEnabled
            );
            if (forceResult.data.result == "Success") {
              await this.alertNoti("계통 집계가 활성화 되었습니다.");
              await this.getTreeList();
              await this.$refs["tree-detail"].clearData();
            } else await this.alertNoti("계통 집계 활성화에 실패하였습니다.");
          } else return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    logDragStart(node) {
      this.moveNodeIdx = node.data.sysNodeIdx;
      this.fromNodeIdx = node.parent.data.sysNodeIdx;
    },
    async logDragFinish(node) {
      try {
        this.toNodeIdx = node.parent.data.sysNodeIdx;
        let moveNode = {
          sysNodeIdx: this.moveNodeIdx,
          fromNodeIdx: this.fromNodeIdx,
          toNodeIdx: this.toNodeIdx,
        };
        let result = await backEndApi.sysNode.moveSysNode(moveNode);
        if (result.status == 200) {
          this.alertNoti("노드를 이동하였습니다.");
        }
      } catch (e) {
        this.alertNoti("노드 이동에 실패하였습니다.");
        console.error(e);
      }
    },
    onNodeExpand(node) {
      const expandedNodes = this.$refs.tree.findAll({
        state: { expanded: true },
      });

      expandedNodes.forEach((expandedNode) => {
        if (expandedNode.id !== node.id && node.depth === expandedNode.depth) {
          expandedNode.collapse();
        }
      });
    },
    async saveNode(data) {
      let sumEnabled = {
        sysNodeIdx: data.sysNodeIdx,
        sumEnabled: data.sumEnabled,
      };
      try {
        let sumEnabledResult = await backEndApi.sysNode.setSumEnabled(
          sumEnabled
        );
        if (sumEnabledResult.data.result == "Success") {
          await this.saveSysNode(data);
        } else {
          let change = await this.alertConfirmWarning(
            `${sumEnabledResult.data.data[0].sysNodeName}에 자동 집계가 활성화 되어 있습니다. 현재 노드로 집계를 활성화 하시겠습니까?`
          );
          if (change.value) {
            sumEnabled.forceUpdate = "Y";
            await backEndApi.sysNode.setSumEnabled(sumEnabled);
            await this.saveSysNode(data);
          } else return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async saveSysNode(data) {
      try {
        let result = await backEndApi.sysNode.saveSysNode(data);
        if (result.status == 200) {
          await this.alertNoti("저장하였습니다.");
          await this.getTreeList();
          await this.$refs["tree-detail"].clearData();
        } else {
          await this.alertNoti("저장에 실패하였습니다.");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async initSysNodeTree() {
      try {
        let result = await backEndApi.sysNode.initSysNodeTree(this.sysType);
        if (result.status == 200) {
          await this.alertNoti("계통을 활성화 하였습니다.");
          await this.getRootList();
          await this.getTreeList();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.ld-container {
  height: 90vh;
  width: 100%;
  padding: 10px 30px 10px 20px;
  overflow: hidden;

  background-color: white;
  border-radius: 10px;
}

.ld-container .list-area {
  height: 100%;
  overflow: hidden;
  padding: 10px;
  /* border-right: 1px solid #ececec; */
}

.ld-container .detail-area {
  height: 100%;
  margin-right: 0px;
  padding: 0px 10px 0px 10px;

  border-radius: 10px;
  border-top: 1px solid #f4f4f4;
  border-right: 2px solid #e7e7e7;
  border-bottom: 2px solid #e7e7e7;
  border-left: 1px solid #f4f4f4;
  background-color: #f9f9f9;
}

.tree-area {
  height: Calc(100% - 70px);
  /* div 높이가 상위 영역 밖으로 벗어나서 - 70px 로 수정(기존 - 36px) */
  width: 100%;

  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 8px;
  margin-bottom: 0px !important;

  border-radius: 0px 0px 10px 10px;
  border-left: solid #eee 1px;
  border-top: solid #eee 1px;
  border-right: solid #ddd 1px;
  border-bottom: solid #ddd 1px;
  font-size: 0.8rem;
}

.tree-area-placeholder {
  height: Calc(100% - 70px);
  /* div 높이가 상위 영역 밖으로 벗어나서 - 70px 로 수정(기존 - 36px) */
  width: 100%;

  /* overflow-y: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 8px 16px 8px 16px;
  margin-bottom: 0px !important;

  border-radius: 0px 0px 10px 10px;
  border-left: solid #eee 1px;
  border-top: solid #eee 1px;
  border-right: solid #ddd 1px;
  border-bottom: solid #ddd 1px;

  font-size: 0.9rem;
  font-weight: bold;
}

.tree {
  padding-left: 10px;
  width: 100%;
  /* overflow-y: auto; */
}

.detail-notification-icon {
  padding: 1rem;
  color: #444;
  font-size: 48px;
}

.detail-notification-text {
  font-size: 15px;
  font-weight: bold;
}

.tree-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* transition: flex-grow 0.3s ease; */

  /* background-color: #eee; */
  background-color: #f1f1f1;

  padding: 4px 0px 4px 8px;
  margin-bottom: 0px !important;
  font-weight: bold;
  /* display: flex; */
  /* margin-top: 10px; */

  border-radius: 6px 6px 0px 0px;
  border: solid #eee 1px;
  border-bottom: 1px solid #ccc;

  font-size: 0.9rem;
}

.tree-title {
  padding: 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
}

.tree-title .title {
  margin-right: 8px;
}

.control-buttons {
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0px;
  /* height: 5%; */
}

.ctrl-modal-btn {
  flex: 1;
  margin: 0px 5px 0px 5px;
}

.ctrl-modal-header {
  font-size: 14px;
  font-weight: bold;
}

.check-field {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.treenode-icon {
  /* color: #727272; */
  color: #5775ad;
  background-color: #f5f5f5;
  border: solid #e2e2e2 1px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: background-color 0.5s;
  width: 26px;
  height: 26px;
  font-size: 16px;
}

.treenode-icon:hover {
  background-color: #c7c7c7;
}

.treenode-icon.active {
  color: #fff;
  background-color: #555;
}

.control-icon {
  color: white;
  background-color: #ed7d31;
  border: solid #fff 1px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2rem;
  transition: background-color 0.5s;
  width: 3rem;
  height: 2rem;
}

.used-icon {
  color: white;
  background-color: #666;
  border: solid #fff 1px;
  text-wrap: nowrap;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2rem;
  transition: background-color 0.5s;
  width: 3rem;
  height: 2rem;
}

.used-icon:hover {
  background-color: #999;
}

.control-icon:hover {
  background-color: #eab897;
}

.status-icon {
  color: #555;
  background-color: #fff;
  border: solid #bbb 1px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2rem;
  transition: background-color 0.5s;
  width: 3rem;
  height: 2rem;
}

.status-icon:hover {
  background-color: #c7c7c7;
}
</style>