<template>
  <div>
    <b-modal
      centered
      ref="subSystemModal"
      size="md"
      @hidden="hideSubSystemModal"
    >
      <template #modal-header>
        <h5 class="mb-0">하위 계통 추가</h5>
      </template>
      <template #modal-footer>
        <div style="display: flex; flex: 1">
          <button type="button" class="btn btn-primary group-modal-btn">
            저장
          </button>
          <button
            type="button"
            class="btn btn-secondary group-modal-btn"
            @click="hideSubSystemModal"
          >
            닫기
          </button>
        </div>
      </template>
      <div class="mb-3" style="font-weight: 600; font-size: 0.8rem">
        <span>선택된 노드의 하위에 새로운 계통 노드를 추가합니다.</span>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sysNodeName">노드명</label>
          <input type="text" class="form-control" id="sysNodeName" />
        </div>
        <div class="form-group col-md-3">
          <label for="zoneIdx">구역정보</label>
          <select id="zoneIdx" class="form-control">
            <option :value="null">선택</option>
            <option
              v-for="zone in zoneInfo"
              :key="zone.value"
              :value="zone.value"
            >
              {{ zone.text }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="enabled">사용여부</label>
          <select id="enabled" class="form-control">
            <option :value="null">선택</option>
            <option
              v-for="enable in commonCodes.enabled"
              :key="enable.value"
              :value="enable.value"
            >
              {{ enable.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="sysType">계통 유형</label>
          <select id="sysType" class="form-control">
            <option :value="null">선택</option>
            <option
              v-for="type in transSysType"
              :key="type.value"
              :value="type.value"
            >
              {{ type.text }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="enabled">설비 유형</label>
          <select id="enabled" class="form-control">
            <option :value="null">선택</option>
            <option
              v-for="type in transEquipType"
              :key="type.value"
              :value="type.value"
            >
              {{ type.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="description">설명</label>
          <textarea
            type="text"
            class="form-control"
            id="description"
            style="resize: none; overflow-y: auto"
            rows="3"
          ></textarea>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["data"],
  components: {},
  data() {
    return {
      subSystemData: null,
    };
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      commonCodes: (state) => state.commonCodes,
    }),
    ...mapState({
      zoneInfo: (state) => state.zoneInfo,
    }),
    ...mapState({
      transSysType: (state) => state.transSysType,
    }),
    ...mapState({
      transEquipType: (state) => state.transEquipType,
    }),
  },
  methods: {
    setData(data) {
      if (data) {
        this.subSystemData = data;
      } else {
        this.subSystemData = this.initData();
      }
    },
    initData() {
      return {
        sysNodeIdx: null,
        sysNodeName: null,
        nodeType: "System",
        sysType: this.data.sysType,
        equipType: this.data.equipType,
        zoneIdx: null,
        equipIdx: null,
        enabled: "Y",
        description: null,
      };
    },
    showSubSystemModal() {
      this.$refs.subSystemModal.show();
    },
    async hideSubSystemModal() {
      await this.$refs.subSystemModal.hide();
      this.subSystemData = null;
    },
    async save() {
      await this.$emit("save");
      await this.$refs.subSystemModal.hide();
      this.subSystemData = null;
    },
  },
};
</script>

<style scoped>
.group-modal-btn {
  flex: 1;
  margin: 0px 5px 0px 5px;
}
</style>