<template>
    <div class="row">
        <div class="col-md-12 p-0 mb-3" style="font-weight: 600; font-size: 1rem;">
            <!-- <span v-if="nodeInfo && nodeInfo.nodeType == 'Group'">설비 추가</span>
            <span v-else>설비 조회</span> -->
            <span>
                {{ routeType == 'energy' ? '부하 조회' : '설비 조회' }}
            </span>
        </div>
        <div class="col-md-4 p-0">
            <label for="equipType">{{ routeType == 'energy' ? '부하 유형' : '설비 유형' }}</label>
            <select v-model="equipType" class="form-control" id="equipType" @change="selectEquipType">
                <option :value="null">선택</option>
                <option v-for="(type) in equipTypeList" :key="type.equipType" :value="type.equipType">
                    {{ type.equipTypeName }}
                </option>
            </select>
        </div>
        <div class="col-md-3 p-0">
            <label for="zoneIdx">적용 구역</label>
            <select v-model="zoneIdx" class="form-control" id="zoneIdx">
                <option :value="null">선택</option>
                <option v-for="(zone) in zoneInfo" :key="zone.value" :value="zone.value">
                    {{ zone.text }}
                </option>
            </select>
        </div>
        <div class="col-md-3 p-0">
            <label for="equipUseType">소비 용도</label>
            <select v-model="equipUseType" class="form-control" id="equipUseType">
                <option :value="null">선택</option>
                <option v-for="(code) in phscPointUsage" :key="code.text" :value="code.value">
                    {{ code.text }}
                </option>
            </select>
        </div>
        <div class="col-md-2 d-flex justify-content-end align-items-end p-0">
            <button type="button" class="btn btn-primary" style="height: 35px;" @click="getEquipList">조회</button>
        </div>
        <div class="col-md-12 mt-3 p-0" style="height: 100%;">
            <TableList ref="TableList" :title="''" :columns="columns" :rows="equipList" :keyField="''"
                :transCodes="transCodes" :mode="''" :useMaxHeight="false" :useFilter="false" />
        </div>
    </div>
</template>

<script>
import backEndApi from "@src/api/backEndApi";
import { mapState } from 'vuex';
import TableList from "@src/views/component/v2.1/ListDetailView/TableList.vue"

export default {
    props: ["nodeInfo", "routeType", "type"],
    components: {
        TableList,
    },
    data() {
        return {
            transCodes: [
                {
                    field: 'equipCategory',
                    codes: this.$store.state.commonCodes.equipCategory,
                },
                {
                    field: 'equipUseType',
                    codes: this.$store.state.commonCodes.itemUseageCode,
                },
                {
                    field: 'zoneIdx',
                    codes: this.$store.state.zoneInfo,
                },
                {
                    field: 'enabled',
                    codes: this.$store.state.commonCodes.enabled,
                },
            ],
            columns: [
                {
                    label: this.$t('설비명'),
                    field: 'equipName',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    compositionFields: ['equipName', 'equipIdx']
                },
                {
                    label: this.$t('연동 장치'),
                    field: 'deviceInfo',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    compositionFields: ['deviceName', 'deviceIdx']
                },
                {
                    label: this.$t('장치 모듈'),
                    field: 'moduleInfo',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                    compositionFields: ['moduleName', 'moduleIdx']
                },
                {
                    label: this.$t('설비 분류'),
                    field: 'equipCategory',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('설비 용도'),
                    field: 'equipUseType',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('구역'),
                    field: 'zoneIdx',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: this.$t('사용여부'),
                    field: 'enabled',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            equipType: null,
            zoneIdx: null,
            equipUseType: null,
            equipList: null,

            selectEquip: null,
            
        }
    },
    created() { },
    mounted() {
        if(this.nodeInfo) {
            this.equipType = this.nodeInfo.equipType;
            this.zoneIdx = this.nodeInfo.zoneIdx;
            this.equipUseType = this.nodeInfo.equipUseType;
        } else {
            this.equipType = this.type ?? null;
        }
    },
    destroyed() { },
    watch: {},
    computed: {
        ...mapState({
            commonCodes: state => state.commonCodes
        }),
        ...mapState({
            zoneInfo: state => state.zoneInfo
        }),
        ...mapState({
            phscPointUsage: state => state.commonCodes.phscPointUsage
        }),
        ...mapState({
            systemTypeList: state => state.systemTypeList
        }),
        ...mapState({
            equipTypeList: state => state.equipTypeList
        }),
        ...mapState({
            transSysType: state => state.transSysType
        }),
        ...mapState({
            transEquipType: state => state.transEquipType
        }),
    },
    methods: {
        async getEquipList() {
            try {
                let result = await backEndApi.equips.getEquipList(this.equipType, this.zoneIdx, this.equipUseType);
                if(result.status == 200) {
                    this.equipList = result.data;
                } else {
                    this.alertNoti("해당 유형의 설비가 없습니다.")
                }
            } catch (e) {
                console.error(e)
            }
        },
        selectEquipType() {
            this.equipList = null;
        },
        async selectedEquip() {
            this.selectEquip = await this.$refs.TableList.getCheckedRows();
            return this.selectEquip;
        }
    },
}

</script>